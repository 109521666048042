import { ResultModal } from 'UI';
import { CourseDtoTypeEnum, PaymentDtoLevelEnum, PaymentDtoStatusEnum, PaymentDtoTypeEnum } from 'api/generated';
import { AxiosError } from 'axios';
import { ChooseProductModal, PaymentModal, Unsubscribe } from 'components';
import { accessLevels } from 'constant';
import { useAsyncAction, useNotifications, useToggle } from 'hooks';
import { FC, ReactNode, useEffect } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';

type CourseProviderProps = {
  children: ReactNode;
};

const CourseProvider: FC<CourseProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { errorToast } = useNotifications();
  const { isOpen: isUnsubscribeModalOpen, close: closeUnsubscribeModal, open: openUnsubscribeModal } = useToggle();
  const { isOpen: isSuccessModalOpen, close: closeSuccessModal, open: openSuccessModal } = useToggle();

  const accessToken = useAppSelector(selectors.auth.selectAccessToken);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const selectedCourse = useAppSelector(selectors.courses.selectCourse);
  const isChooseProductModalOpen = useAppSelector(selectors.courses.selectIsChooseProductModalOpen);
  const isPaymentModalOpen = useAppSelector(selectors.courses.selectIsPaymentModalOpen);
  const courseOptionsForPayment = useAppSelector(selectors.courses.selectCourseOptionsForPayment);

  const [getActiveCourseAction] = useAsyncAction(actions.courses.getActiveCourse);
  const [getCourseStatisticAction] = useAsyncAction(actions.performance.getCourseStatistic);
  const [unsubscribeCourseAction, isUnsubscribing] = useAsyncAction(actions.courses.unsubscribeCourse);
  const openChooseProductModal = () => dispatch(actions.courses.openChooseProductModalOpen());
  const closeChooseProductModal = () => {
    dispatch(actions.courses.selectCourse(null));
    dispatch(actions.courses.closeChooseProductModalOpen());
  };
  const closePaymentModal = () => {
    dispatch(actions.courses.closePaymentModalOpen());
  };

  const isCreateUserPathname = window.location.pathname == '/create-user/';
  const isSubscribeToCoursePathname = window.location.pathname == '/subscribe-to-course/';

  const course = selectedCourse || activeCourse;

  const selectCourseIsTrial = selectedCourse?.currentPayment.type === 'Free';

  const hasStatisticAccess =
    activeCourse?.currentPayment?.level === PaymentDtoLevelEnum.Gold ||
    activeCourse?.currentPayment?.type === PaymentDtoTypeEnum.Free;

  useEffect(() => {
    if (accessToken && !isCreateUserPathname && !isSubscribeToCoursePathname) {
      getActiveCourse();
    }
  }, [accessToken, isCreateUserPathname, isSubscribeToCoursePathname]);

  const getActiveCourse = () => {
    try {
      getActiveCourseAction();
    } catch (error) {
      console.log(error);
    }
  };

  const dateStartPauseSubscription = activeCourse?.currentPayment.onPauseFrom;
  const dateEndPauseSubscription = activeCourse?.currentPayment.onPauseUntil;
  const isPauseCertificate = activeCourse?.currentPayment?.status === PaymentDtoStatusEnum.Trialing;
  const isInstallment = activeCourse?.currentPayment.type === PaymentDtoTypeEnum.Installment;

  const checkPause = (start: string | undefined | null, end?: string | undefined | null) => {
    if (!start || !end) {
      return;
    }
    const now = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    return now >= startDate && now <= endDate;
  };

  const pauseStatus = checkPause(dateStartPauseSubscription, dateEndPauseSubscription);

  dispatch(actions.courses.setPauseSubscription(pauseStatus));
  dispatch(actions.courses.setPauseCertificate(isPauseCertificate && isInstallment));

  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  useEffect(() => {
    if (accessToken && !isCreateUserPathname && hasStatisticAccess) {
      getCourseStatistic();
    }
  }, [hasPerformance, accessToken, activeCourse?.id, isCreateUserPathname]);

  const getCourseStatistic = async () => {
    if (hasPerformance && !pauseStatus) {
      try {
        await getCourseStatisticAction();
      } catch (error) {
        errorToast('Something went wrong');
      }
    }
  };

  const onCloseUnsubscribeModal = () => {
    closeUnsubscribeModal();
    openChooseProductModal();
  };

  const unsubscribeFromCourse = async () => {
    try {
      if (course) {
        await unsubscribeCourseAction({ courseId: course.id });

        ('You have unsubscribed from the course');
      } else {
        throw course;
      }
      dispatch(actions.courses.setIsSuccessfulUnsubscribe(true));
    } catch (error) {
      dispatch(actions.courses.setIsSuccessfulUnsubscribe(false));
      errorToast((error as AxiosError)?.message || 'Something went wrong');
    } finally {
      closeUnsubscribeModal();
    }
  };

  return (
    <>
      {children}
      {course && (
        <ChooseProductModal
          course={course}
          isOpen={isChooseProductModalOpen}
          onRequestClose={closeChooseProductModal}
          openUnsubscribeModal={openUnsubscribeModal}
        />
      )}

      <Unsubscribe
        isOpen={isUnsubscribeModalOpen}
        onClose={onCloseUnsubscribeModal}
        onUnsubscribe={unsubscribeFromCourse}
        isLoading={isUnsubscribing}
        courseId={course?.id}
        closeChooseProductModal={closeChooseProductModal}
      />

      {courseOptionsForPayment && isPaymentModalOpen && (
        <PaymentModal
          openSuccessModal={openSuccessModal}
          onClose={closePaymentModal}
          isOpen={isPaymentModalOpen}
          courseId={courseOptionsForPayment.courseId}
          subscribeType={courseOptionsForPayment.subscribeType}
          term={courseOptionsForPayment.term}
          amount={courseOptionsForPayment.amount}
          isCertificate={courseOptionsForPayment.type === CourseDtoTypeEnum.Certificate}
          oldAmount={courseOptionsForPayment.oldAmount}
          isUpdate={courseOptionsForPayment.isUpdate}
          isActivate={course?.isActive}
          isTrial={selectCourseIsTrial}
          couponCode={courseOptionsForPayment.couponCode}
        />
      )}
      <ResultModal
        variant="success"
        title="Subscription payment was successful"
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
    </>
  );
};

export default CourseProvider;

import { Stripe, StripeElements } from '@stripe/stripe-js';
import { PaymentMethodDto } from 'api/generated';
import { AppConfig } from 'config';
import { routes } from 'constant/routes';
import { useStripeSocket } from 'hooks';
import { useState } from 'react';
import { selectors, useAppSelector } from 'store';
import { setErrorStripe } from 'utils';

interface HandleSetupStripeFormArgs {
  stripe: Stripe | null;
  elements?: StripeElements;
  clientSecret: string;
  paymentMethod?: PaymentMethodDto;
}

const returnUrl = new URL(routes.addNewCourse, AppConfig.BASE_API_URL || '');

export const useStripeSetupForm = () => {
  const [setupErrorMessage, setSetupErrorMessage] = useState<string | null>(null);

  const token = useAppSelector(selectors.auth.selectAccessToken);

  const { onPaymentMethodChange } = useStripeSocket({
    errorMessage: setupErrorMessage,
  });

  const handleSetupStripeForm = async ({
    stripe,
    elements,
    clientSecret,
    paymentMethod,
  }: HandleSetupStripeFormArgs) => {
    if (!stripe) return;

    const params = {
      elements,
      clientSecret,
      redirect: 'if_required' as const,
      confirmParams: {
        return_url: returnUrl.href,
        payment_method: paymentMethod?.id,
      },
    };

    try {
      elements &&
        (await elements.submit().then((res) => {
          return res;
        }));

      const res = await stripe.confirmSetup(params);
      if (token && !paymentMethod) onPaymentMethodChange();
      return res;
    } catch (error: any) {
      setErrorStripe({
        error: error,
        setErrorMessage: setSetupErrorMessage,
      });
    }
  };

  return {
    handleSetupStripeForm,
    setupErrorMessage,
  };
};

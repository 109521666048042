import { Input, StyledText } from 'UI';
import { MarkIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import Loader from 'components/Loader';
import { useAsyncAction } from 'hooks';
import { FC, useRef, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { validations } from 'utils';

type AuthorizedInputProps = {
  setIsAuthorized: (value: boolean) => void;
  courseId: string;
};

const AuthorizedInput: FC<AuthorizedInputProps> = ({ setIsAuthorized }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { colors } = useTheme();

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');

  const [error, setError] = useState('');

  const direction = useAppSelector(selectors.direction.selectDirection);
  const directionId = direction?.id || '';

  const [getCustomerByEmail] = useAsyncAction(actions.stripe.getCustomerByEmail);

  const [emailForError, setEmailForError] = useState('');
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [customerIdLoading, setCustomerIdLoading] = useState(false);

  const getCustomer = async (email: string, directionId: string) => {
    setCustomerIdLoading(true);
    setIsExistingCustomer(false);

    try {
      const { isExistingCustomer } = await getCustomerByEmail({ email, directionId });

      if (isExistingCustomer) {
        setIsExistingCustomer(isExistingCustomer);
        setEmailForError(email);
        setCustomerIdLoading(false);
        setIsAuthorized(false);
      }

      if (!isExistingCustomer) {
        setIsExistingCustomer(isExistingCustomer);
        setCustomerIdLoading(false);
        setIsAuthorized(true);

        dispatch(actions.auth.setEmail(email));
      }
    } catch (error) {
      setCustomerIdLoading(false);
      setError((error as AxiosError).message);
      setIsAuthorized(false);
    }
  };

  const signUpByEmail = async () => {
    const validationError = validations.email(email);

    if (validationError) {
      setError(validationError);
      return;
    } else {
      setError('');
    }

    if (directionId) {
      await getCustomer(email, directionId);
    }
  };

  const handleOnChange = (e: any) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <Input
        ref={inputRef}
        placeholder="Email"
        value={email}
        onChange={handleOnChange}
        onKeyUp={handleKeyUp}
        onBlur={signUpByEmail}
        disabled={customerIdLoading}
        label="Email"
        showIcon={Boolean(!isExistingCustomer)}
        iconComponent={
          customerIdLoading ? <Loader width={18} height={18} /> : <MarkIcon color={colors.system.green} size={18} />
        }
        maxLength={155}
      />
      <ErrorText>
        {isExistingCustomer ? (
          <div style={{ display: 'flex' }}>
            <p>
              We noticed that you already have a trial account with {emailForError}. Please
              <a
                style={{ textDecoration: 'underline', marginLeft: '3px', marginRight: '3px' }}
                href="https://study.traineracademy.org/"
                target="_blank"
                rel="noreferrer">
                log in
              </a>
              here to purchase.
            </p>
          </div>
        ) : (
          error
        )}
      </ErrorText>
    </>
  );
};

export default AuthorizedInput;

const ErrorText = styled(StyledText)`
  text-align: start;
  margin-bottom: 16px;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
    `}
  `};
`;

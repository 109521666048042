export const AppConfig = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  REACT_APP_WS_API_URL: process.env.REACT_APP_WS_API_URL,
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_PUBLISHABLE_KEY,
  REACT_APP_AMPLITUDE_KEY: process.env.REACT_APP_AMPLITUDE_KEY || '',
  REACT_APP_INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID || '',
  REACT_APP_TRACK_GOOGLE_ANALYTICS: process.env.REACT_APP_TRACK_GOOGLE_ANALYTICS,
  REACT_APP_START_MAINTENANCE_TIMESTAMP: process.env.REACT_APP_END_MAINTENANCE_TIMESTAMP,
  REACT_APP_END_MAINTENANCE_TIMESTAMP: process.env.REACT_APP_END_MAINTENANCE_TIMESTAMP,
};

import gift from 'assets/json/gift_white_3d.json';
import capitalGift from 'assets/json/pilot-gift.json';

export const giftAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalGift, // localhost 3000 direction
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalGift, // capital direction
  'ff9c663b-19ea-491d-a1af-d823a6845831': gift, // trainer academy direction
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': gift, // stage direction
  '01f94266-8371-42d6-bc52-961a53dc65e0': gift, // pilot academy direction
  'a7be9322-6431-4715-b088-d716ddc61bdf': gift, // local host 3001
};

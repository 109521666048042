import { tabs } from 'constant';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

interface ResponsiveTabsProps {
  isShow: boolean;
  activePeriod: string;
  mainColor: string | undefined;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setActivePeriod: Dispatch<SetStateAction<string>>;
}

export const ResponsiveTabs: FC<ResponsiveTabsProps> = ({
  isShow,
  activePeriod,
  mainColor,
  setActiveIndex,
  setActivePeriod,
}) => {
  return (
    <>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            isShow={isShow}
            mainColor={mainColor}
            isActive={activePeriod === tab.id}
            onClick={() => {
              setActiveIndex(index);
              setActivePeriod(tab.id);
            }}>
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
    </>
  );
};

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 8px;
`;

const Tab = styled.div<{ isShow: boolean; isActive: boolean; mainColor: string | undefined }>`
  padding: 6px 27px;
  border-radius: 8px;
  color: ${({ theme: { colors }, isActive }) => (isActive ? '#FFFFFF' : colors.neutrals[1])};
  background: ${({ theme: { colors }, isActive, mainColor }) => (isActive ? `${mainColor}` : colors.neutrals[9])};

  @media (max-width: 1180px) {
    padding: 6px 60px;
  }

  @media (max-width: 570px) {
    padding: 6px 36px;
  }

  @media (max-width: 370px) {
    padding: 6px 29px;
  }
`;

import IconButton from 'UI/IconButton';
import StyledText from 'UI/StyledText';
import { ArrowBackIcon, CloseIcon } from 'assets/icons';
import { useClientSize } from 'hooks';
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import ReactModal, { Props } from 'react-modal';
import styled, { CSSProp, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

export type DefaultModalProps = {
  title?: string;
  containerCSS?: CSSProp;
  modalCSS?: CSSProp;
  titleCSS?: CSSProp;
  iconColor?: string;
  closeButtonCSS?: CSSProp;
  headerContainerCSS?: CSSProp;
  withTitleMargin?: boolean;
  showCloseButton?: boolean;
  changeCloseButton?: boolean;
  showBackButton?: boolean;
  isMobileOverlayStyles?: boolean;
  onBackIconClick?: () => void;
  onMobileCloseButtonClick?: () => void;
  withLogo?: boolean;
  shouldCloseOnOverlayClick?: boolean;
} & Props;

const DefaultModal: FC<DefaultModalProps> = ({
  title,
  containerCSS = {},
  titleCSS = {},
  modalCSS = {},
  headerContainerCSS = {},
  closeButtonCSS = {},
  iconColor,
  showCloseButton = true,
  changeCloseButton = false,
  showBackButton = false,
  isMobileOverlayStyles = false,
  withTitleMargin = true,
  shouldCloseOnOverlayClick = true,
  onBackIconClick,
  onRequestClose,
  onMobileCloseButtonClick,
  children,
  isOpen,
  ...props
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const { getIsBreakpoint } = useClientSize();
  const { colors } = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);

  const isWidthS = getIsBreakpoint('s');

  // useOnClickOutside(modalRef, (e) => onRequestClose?.(e as unknown as React.MouseEvent<Element, MouseEvent>));

  useEffect(() => {
    if (isOpen) {
      disableScroll();

      return enableScroll;
    } else {
      enableScroll();
    }
  }, [isOpen, isMobileOverlayStyles]);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';

    if (isMobileOverlayStyles) {
      setScrollTop(document.body.scrollTop);
      scrollTo(0);
    }
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';

    if (isMobileOverlayStyles) {
      scrollTo(scrollTop);
    }
  };

  const scrollTo = (scrollValue: number) => {
    document.body.scrollTop = scrollValue;
  };

  const mobileOverlayStyles: CSSProperties =
    isWidthS && isMobileOverlayStyles
      ? { height: 'calc(100% - 83px)', borderRadius: 0, marginBlock: 'auto 0', zIndex: 10 }
      : {};

  if (!isOpen) {
    return null;
  }

  return (
    <StyledModal
      style={{
        overlay: {
          zIndex: 9999,
          overflowY: 'auto',
          backgroundColor: 'rgba(22 22 22 / 39%)',
          backdropFilter: 'blur(10px)',
          ...mobileOverlayStyles,
        },
      }}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      $CSS={modalCSS}
      {...props}>
      <Wrapper ref={modalRef} $CSS={containerCSS}>
        <HeaderContainer $CSS={headerContainerCSS} $withMargin={withTitleMargin}>
          {showCloseButton && changeCloseButton && (
            <CloseArrow onClick={onMobileCloseButtonClick || onRequestClose}>
              <ArrowBackIcon size={32} color={colors.neutrals[1]} />
            </CloseArrow>
          )}
          {title && <Title $CSS={titleCSS}>{title}</Title>}
        </HeaderContainer>
        {showCloseButton && (
          <Close
            $CSS={closeButtonCSS}
            $hide={changeCloseButton}
            onClick={onRequestClose}
            iconComponent={<CloseIcon size={24} color={iconColor || colors.neutrals[1]} />}
          />
        )}
        {showBackButton && (
          <Back iconComponent={<ArrowBackIcon size={24} color={colors.neutrals[1]} />} onClick={onBackIconClick} />
        )}

        {children}
      </Wrapper>
    </StyledModal>
  );
};

export default DefaultModal;

const StyledModal = styled(ReactModal)<{ $CSS: CSSProp }>`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  ${({ $CSS }) => $CSS}

  ${respondToWidth.sm`
    bottom: 0;
    top: 0;
    transform: translate(-50%, 0%);
  `}
`;

const Wrapper = styled.div<{ $CSS: CSSProp }>`
  position: relative;
  border-radius: 28px;
  padding: 40px 100px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};

  ${respondToWidth.s`
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${({ $CSS }) => $CSS}
`;

const HeaderContainer = styled.div<{ $withMargin: boolean; $CSS: CSSProp }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ $withMargin }) => $withMargin && '32px'};

  ${({ $withMargin }) => respondToWidth.s`
    margin-bottom: ${$withMargin && '26px'} ;
    justify-content: ${$withMargin && 'start'};
  `}
  ${({ $CSS }) => $CSS};
`;

const Close = styled(IconButton)<{ $hide: boolean; $CSS: CSSProp }>`
  position: absolute;
  top: 32px;
  right: 32px;
  ${({ $CSS }) => $CSS};

  ${({ $hide }) => respondToWidth.s`
    display: ${$hide && 'none'};
  `}
`;

// const PaymentWrapper = styled.div`
//   background-color: ${({ theme: { colors } }) => colors.primary[1]};
// `;

const CloseArrow = styled.div`
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-right: 16px;
  display: none;

  ${respondToWidth.s`
    display:block;
  `}
`;

const Back = styled(IconButton)`
  position: absolute;
  top: 32px;
  left: 32px;

  ${respondToWidth.sm`
    display: none;
  `}
`;

const Title = styled(StyledText)<{ $CSS: CSSProp }>`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  ${({ theme: { typography } }) => typography.title_2_bold_32};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_4_bold_24};
  `};

  ${({ theme: { typography, colors } }) => respondToWidth.s`
    ${typography.title_5_bold_20};
    color: ${colors.neutrals[1]};
    text-align:  start;
  `};

  ${({ $CSS }) => $CSS};
`;

import * as amplitude from '@amplitude/analytics-browser';
import { FC, ReactNode, useEffect } from 'react';
import { selectors, useAppSelector } from 'store';

type AmplitudeProviderProps = {
  children: ReactNode;
};

const AmplitudeProvider: FC<AmplitudeProviderProps> = ({ children }) => {
  const user = useAppSelector(selectors.user.selectUser);

  useEffect(() => {
    if (user?.email) {
      amplitude.setUserId(user?.email);
    }
  }, [user?.email]);

  return <>{children}</>;
};

export default AmplitudeProvider;

import { Button } from 'UI';
import { BookIcon } from 'assets/icons';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { trackAmplitudeEvent } from 'utils/trackAmplitudeEvent';

import { useNotifications } from '../../hooks';
import { CancelModal, CategoryModal } from './components';
import { useGetCategory } from './hooks';

type StudyThisButtonProps = {
  className?: string;
};

const StudyThisButton: FC<StudyThisButtonProps> = ({ className }) => {
  const { errorToast } = useNotifications();
  const [isCancelModalVisible, setCancelModalVisible] = useState(false);
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const { currentCategory, leastKnowCategory } = useGetCategory();

  const handleButtonClick = () => {
    if (leastKnowCategory) {
      if (currentCategory.length) {
        setCategoryModalVisible(true);
      } else {
        setCancelModalVisible(true);
      }
    } else {
      errorToast('Can not get Least Know Category');
    }

    trackAmplitudeEvent('Study This Pressed');
  };

  return (
    <Root>
      <StudyButton
        onClick={handleButtonClick}
        className={className}
        variant="primary"
        size="small"
        iconComponent={<BookIcon />}>
        Study This
      </StudyButton>

      <CategoryModal isModalVisible={isCategoryModalVisible} setModalVisible={setCategoryModalVisible} />

      <CancelModal isModalVisible={isCancelModalVisible} setModalVisible={setCancelModalVisible} />
    </Root>
  );
};

export default StudyThisButton;

const Root = styled.div`
  ${respondToWidth.ls`
    align-self: flex-end;
  `}
`;

const StudyButton = styled(Button)`
  flex-direction: row-reverse;
  padding-right: 23px;
  white-space: nowrap;

  & svg {
    margin-left: 8px;
  }

  ${respondToWidth.ls`
    max-width: 169px;
    align-self: flex-end;
  `}

  ${respondToWidth.sm`
    display: none;
  `}
`;

import * as amplitude from '@amplitude/analytics-browser';
import { BaseEvent, EventOptions } from '@amplitude/analytics-types';

export const trackAmplitudeEvent = (
  eventInput: string | BaseEvent,
  eventProperties?: Record<string, any>,
  eventOptions?: EventOptions,
) => {
  amplitude.track(eventInput, eventProperties, eventOptions);
};

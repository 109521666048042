import { createSlice } from '@reduxjs/toolkit';

import { getBillingPortalThunk, getCustomerByEmailThunk, getPaymentIntentSecretThunk } from './actions';

interface StripeSlice {
  customerId: string | null;
  stripeCustomerId: string | null;
  isExistingCustomer: boolean;
  clientSecret?: string;
}

const initialState: StripeSlice = {
  customerId: '',
  stripeCustomerId: '',
  isExistingCustomer: false,
  clientSecret: undefined,
};

export const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerByEmailThunk.fulfilled, (state, { payload }) => {
        state.customerId = payload.customerId;
        state.stripeCustomerId = payload.stripeCustomerId;
        state.isExistingCustomer = payload.isExistingCustomer;
      })
      .addCase(getPaymentIntentSecretThunk.fulfilled, (state, { payload }) => {
        state.clientSecret = payload.clientSecret;
      });
  },
});

export const actions = {
  getCustomerByEmail: getCustomerByEmailThunk,
  getPaymentIntentSecret: getPaymentIntentSecretThunk,
  getBillingPortal: getBillingPortalThunk,
};

export const { reducer } = stripeSlice;

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { CourseShortInfoResponseDto, DirectionDto } from 'api/generated';
import { DefaultRejectValue, ExtraParamsThunkType } from 'types';

export const getDirectionByUrlThunk = createAsyncThunk<
  DirectionDto,
  { url: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('direction/getDirectionByUrl', async ({ url }, { rejectWithValue }) => {
  try {
    const { data } = await api.Directions.directionsControllerGetDirectionByUrl(url);

    return data;
  } catch (error) {
    console.log('error => ', error);
    return rejectWithValue(error as DefaultRejectValue);
  }
});
export const getCoursesByDirection = createAsyncThunk<
  CourseShortInfoResponseDto[],
  { id: string },
  ExtraParamsThunkType<DefaultRejectValue>
>('direction/getCoursesByDirectionUrl', async ({ id }, { rejectWithValue }) => {
  try {
    const { data } = await api.Directions.directionsControllerGetDirectionCourses(id);

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

import { DirectionId } from 'components/Modals/ChooseProductModal/components/LifetimeProducts/types';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { dayjs } from 'services';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';

import { giftAnimatedMapping } from './constants';

interface BonusSectionProps {
  setIsShowBonusModal: Dispatch<SetStateAction<boolean>>;
  isProduct: boolean;
  isCertificate?: boolean;
}

export const BonusSection: FC<BonusSectionProps> = ({ setIsShowBonusModal, isProduct, isCertificate }) => {
  const direction = useAppSelector(selectors.direction.selectDirection);

  const directionId = direction?.id || '';

  const isPilotCarer =
    directionId === '451553b1-e21e-4221-b248-e160177536e6x' || directionId === '01f94266-8371-42d6-bc52-961a53dc65e0';
  const today = dayjs();
  const day = today.date();

  const getOrdinal = (day: number) => {
    if (day % 10 === 1 && day % 100 !== 11) return 'st';
    if (day % 10 === 2 && day % 100 !== 12) return 'nd';
    if (day % 10 === 3 && day % 100 !== 13) return 'rd';
    return 'th';
  };
  return (
    <>
      <BonusContainer
        isProduct={isProduct}
        isPilotCarer={isPilotCarer}
        isCertificate={isCertificate}
        onClick={() => setIsShowBonusModal(true)}>
        <Lottie animationData={giftAnimatedMapping[directionId as DirectionId]} />
        <div>
          <BonusTitle>Click to see MVP bonuses</BonusTitle>
          <BonusTitle style={{ minWidth: '70px', maxWidth: '115px' }}>{`for ${today.format('MMMM ')}${day}${getOrdinal(
            day,
          )} `}</BonusTitle>
        </div>
      </BonusContainer>
    </>
  );
};

const BonusContainer = styled.div<{ isProduct: boolean; isPilotCarer: boolean; isCertificate: boolean | undefined }>`
  width: 250px;
  display: flex;
  margin: ${({ isProduct }) => (isProduct ? 'unset' : '20px 0')};
  justify-content: center;
  align-items: center;
  top: ${({ isProduct, isCertificate }) => (isProduct ? (isCertificate ? '560px' : '530px') : 'unset')};
  left: ${({ isProduct }) => (isProduct ? '35px' : 'unset')};
  position: ${({ isProduct }) => (isProduct ? 'absolute' : 'unset')};
  cursor: pointer;
`;

const BonusTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.primary[1]};
  border-bottom: 1px dashed ${({ theme: { colors } }) => colors.primary[1]};
`;

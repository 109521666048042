import { DirectionDto } from 'api/generated';
import { routes } from 'constant/routes';
import { useAsyncAction, useThemeMode } from 'hooks';
import { FC, ReactNode, useEffect, useState } from 'react';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import { Oval as Loader } from 'react-loader-spinner';
import { actions, selectors, useAppSelector } from 'store';
import styled, { ThemeProvider } from 'styled-components';

type DirectionThemeProviderProps = {
  children: ReactNode;
};

const DirectionThemeProvider: FC<DirectionThemeProviderProps> = ({ children }) => {
  const [getDirectionByUrl, isLoading] = useAsyncAction(actions.direction.getDirectionByUrl);
  const direction = useAppSelector(selectors.direction.selectDirection);
  const [directionInfo, setDirectionInfo] = useState<DirectionDto | null>(null);
  const theme = useThemeMode(direction);
  const pathname = window.location.pathname;
  const siteUrl = window.location.origin;
  const isShowApp = !isLoading && direction;
  const isIframePage = pathname.includes(routes.createUser);

  const getCurrentDirection = async (url: string) => {
    await getDirectionByUrl({ url });
  };

  useEffect(() => {
    setDirectionInfo(direction);
  }, [direction]);

  useEffect(() => {
    getCurrentDirection(siteUrl);
  }, [siteUrl]);

  if (isShowApp) {
    return (
      <>
        <Helmet>
          <meta property="og:title" content={directionInfo?.linkTitle || 'LMS'} />
          <meta
            property="og:description"
            content={directionInfo?.linkDescription || 'Web site created using create-react-app'}
          />
          <meta
            name="description"
            content={directionInfo?.linkDescription || 'Web site created using create-react-app'}
          />
          <link rel="icon" href={directionInfo?.faviconUrl} type="image/png" sizes="192x192" />
          <link rel="icon" href={directionInfo?.faviconUrl} type="image/png" sizes="512x512" />
          <title>{directionInfo?.linkTitle || 'LMS'}</title>
        </Helmet>

        <Favicon url={directionInfo?.faviconUrl} />
        <ThemeProvider theme={theme.default}>{children}</ThemeProvider>
      </>
    );
  }

  return (
    <Root $withBackground={!isIframePage}>
      {isLoading ? (
        <Loader width={80} height={80} strokeWidth={4} strokeWidthSecondary={4} color="black" secondaryColor="fray" />
      ) : (
        <>Not found</>
      )}
    </Root>
  );
};

export default DirectionThemeProvider;

const Root = styled.div<{ $withBackground: boolean }>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $withBackground }) => $withBackground && 'background-color: white;'};
`;

import { Button, DefaultModal } from 'UI';
import StyledText from 'UI/StyledText';
import exam from 'assets/images/exam.png';
import progressGreen from 'assets/json/progress_green.json';
import { pluralForms } from 'constant';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { useClientSize, useGetProgressColor } from 'hooks';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import React, { FC, memo, useDeferredValue, useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { getPoints, handleWordsDeclination } from 'utils';

type StreakModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const StreakModal: FC<StreakModalProps> = ({ isOpen, onClose }) => {
  const { colors } = useTheme();
  const { getIsBreakpoint } = useClientSize();
  const isBreakpointSm = getIsBreakpoint('sm');
  const isBreakpointXs = getIsBreakpoint('xs');
  const coursePoints = useAppSelector(selectors.performance.selectCourseStatistic);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [count, setCount] = useState(0);
  const streakCount = useAppSelector((store) => store.performance.courseStatistic?.streak);

  const points = coursePoints?.totalInPoints || 0;
  const deferredPoints = useDeferredValue(points);
  const value = (deferredPoints / MAXIMUM_COURSE_POINTS) * 100;
  const color = useGetProgressColor(value);

  const animateCount = () => {
    const roundCount = Math.round(count);
    const roundDeferredPoints = Math.round(deferredPoints);

    if (roundCount >= roundDeferredPoints) {
      setCount(deferredPoints);
    } else {
      setTimeout(() => {
        setCount((prev) => prev + deferredPoints / 60);
      }, 1000 / 60);
    }
  };

  const animateFlush = () => {
    lottieRef.current?.goToAndPlay(0);
  };

  useEffect(animateCount, [count, deferredPoints]);
  useEffect(animateFlush, [deferredPoints]);

  return (
    <DefaultModal
      isOpen={isOpen}
      onRequestClose={onClose}
      containerCSS={ContainerCSS}
      modalCSS={ModalCSS}
      withTitleMargin={false}
      shouldCloseOnOverlayClick={true}
      showCloseButton={!isBreakpointSm}
      closeButtonCSS={CloseCSS}>
      <Wrapper>
        <AnimationContainer>
          <Lottie animationData={progressGreen} lottieRef={lottieRef} loop={false} />
        </AnimationContainer>
        <ProgressContainer>
          <Scale src={exam} alt="Exam scale" />
          <CircularProgressbar
            value={value}
            circleRatio={0.5}
            strokeWidth={10}
            styles={buildStyles({
              trailColor: colors.neutrals[9],
              strokeLinecap: 'round',
              pathColor: color,
              rotation: 0.75,
              pathTransitionDuration: 0.8,
            })}
          />
          <Legends>
            <Count $color={color}>{getPoints(count)}</Count>
            <Text>points out of {getPoints(MAXIMUM_COURSE_POINTS)}</Text>
          </Legends>
        </ProgressContainer>
      </Wrapper>
      <StreakText isBreakpointXs={isBreakpointXs}>
        You are on a {streakCount} {handleWordsDeclination(streakCount || 0, pluralForms.days)} study streak!
      </StreakText>
      <ButtonsContainer>
        <CancelButton
          key="cancel"
          onClick={onClose}
          // className={className}
          variant={isBreakpointSm ? 'secondary' : 'primary'}
          size="middle">
          Cancel
        </CancelButton>
      </ButtonsContainer>
    </DefaultModal>
  );
};

export default memo(StreakModal);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  transform: translateY(-50px);
`;

const Scale = styled.img`
  position: absolute;
  top: 37px;
  left: 38px;
  width: 194px;
`;

const Legends = styled.div`
  position: absolute;
  top: auto;
  bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: -15%;
  width: 130%;
`;

const ProgressContainer = styled.div`
  width: 90%;
  height: 170px;
  margin-top: 80px;
  position: relative;
  z-index: 1;

  svg {
    width: 100%;
  }

  ${respondToWidth.sm`
    width: 270px;
  `}
`;

const Count = styled(StyledText)<{ $color: string }>`
  ${({ theme: { typography }, $color }) => css`
    z-index: 1;
    color: ${$color};

    ${typography.title_2_bold_32};
  `};
`;

const Text = styled(StyledText)`
  ${({ theme: { colors, typography } }) => css`
    padding-top: 10px;
    color: ${colors.neutrals[1]};

    ${typography.body_large_semibold_16};
  `};
`;

const StreakText = styled.p<{ isBreakpointXs: boolean }>`
  ${({ theme: { typography }, isBreakpointXs }) => css`
    position: relative;
    top: -38px;
    margin-bottom: ${isBreakpointXs ? '12px' : '38px'};
    text-align: center;
    color: #55b47d;

    ${typography.title_4_bold_24};
  `};
`;

const ContainerCSS = css`
  max-width: 500px;
  width: 500px;
  height: 420px;
  max-height: 420px;
  border-radius: 28px;
  overflow: hidden;

  ${respondToWidth.s`
    padding: 40px 71px;
  `}
`;

const ModalCSS = css`
  ${respondToWidth.sm`
    top: unset;
    bottom: 0;
    width: 100%;
    transform: translate(-50%, 0);
  `}
`;

const CloseCSS = css`
  ${respondToWidth.s`
    display:none;
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  top: -38px;
  flex-direction: column;
  margin-top: auto;
`;

const CancelButton = styled(Button)`
  display: flex !important;
  width: 100% !important;
  padding-right: 23px;
  white-space: nowrap;
`;

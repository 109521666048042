import { Elements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { StripeElement } from 'components';
import { useStripeProviderOptions } from 'hooks';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { selectors, useAppSelector } from 'store';

type UnauthorizedStripeProviderProps = {
  children: ReactNode;
  showEmptyState?: boolean;
  clientSecret?: string;
  setStripe?: (stripe: Stripe | null) => void;
  setElements?: (elements: StripeElements | null) => void;
  onConfirm?: any;
};

// @ts-ignore
const UnauthorizedStripeProvider: FC<UnauthorizedStripeProviderProps> = ({
  children,
  clientSecret,
  showEmptyState = false,
  setStripe,
  setElements,
  onConfirm,
}) => {
  const direction = useAppSelector(selectors.direction.selectDirection);
  const [stripe, setStripeInstance] = useState<Stripe | null>(null);
  const isActiveForm = Boolean(clientSecret);

  const publicKey = direction?.stripePublicKey;

  useEffect(() => {
    const loadStripeInstance = async () => {
      if (publicKey) {
        const stripeInstance = await loadStripe(publicKey);
        setStripeInstance(stripeInstance);
        setStripe?.(stripeInstance);
      }
    };

    loadStripeInstance();
  }, [publicKey]);

  const { options } = useStripeProviderOptions(isActiveForm);
  const { appearance, fonts, locale } = options;

  useEffect(() => {
    const handleMount = async () => {
      setStripe?.(await stripe);
    };

    handleMount();
  }, [stripe]);

  if (!clientSecret && !showEmptyState) {
    return null;
  }

  if (!clientSecret) {
    return (
      <>
        <Elements
          stripe={stripe}
          key="elements without client secret"
          options={{
            ...{
              mode: 'setup',
              currency: 'usd',
              loader: 'always',
              appearance,
              fonts,
              locale,
            },
          }}>
          {children}
        </Elements>
      </>
    );
  }

  if (clientSecret) {
    return (
      <Elements stripe={stripe} key={clientSecret} options={{ clientSecret, appearance, fonts, locale }}>
        <ExpressCheckoutElement
          options={{
            wallets: { applePay: 'always', googlePay: 'always' },
            layout: { maxColumns: 1, overflow: 'never' },
          }}
          onConfirm={onConfirm}
          onClick={({ resolve }) => {
            resolve({
              emailRequired: true,
            });
          }}
        />
        <StripeElement setElements={setElements}>{children}</StripeElement>
      </Elements>
    );
  }
};

export default UnauthorizedStripeProvider;

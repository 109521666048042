import capitalDarkDribbling from 'assets/json/capital-dark-dribbling.json';
import capitalDarkRiseCup from 'assets/json/capital-dark-rise-cup.json';
import capitalDribbling from 'assets/json/capital-dribbling-animated.json';
import capitalRiseCup from 'assets/json/capital-rise-cup-animated.json';
import capitalThrowInBasket from 'assets/json/capital-throw-in-basket-animated.json';
import capitalDarkThrowInBasket from 'assets/json/capital-throw-in-basket-dark.json';
import pilotDarkDribbling from 'assets/json/pilotDarkDribbling.json';
import pilotDarkRiseCup from 'assets/json/pilotDarkRiseCup.json';
import pilotDarkThrowInBasket from 'assets/json/pilotDarkThrowInBasket.json';
import pilotDribbling from 'assets/json/pilotDribbling.json';
import pilotRiseCup from 'assets/json/pilotRiseCup.json';
import pilotThrowInBasket from 'assets/json/pilotThrowInBasket.json';
import raiseСup from 'assets/json/pricing-animate-1.json';
import throwInBasket from 'assets/json/pricing-animate-2.json';
import dribbling from 'assets/json/pricing-animate-3.json';
import throwInBasketDark from 'assets/json/pricing_icon_1_V1(square).json';
import dribblingDark from 'assets/json/pricing_icon_2_V1(square).json';
import raiseСupDark from 'assets/json/pricing_icon_3_V1(square).json';

export const goldAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalRiseCup, // localhost 3000 direction
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalRiseCup, // capital direction
  'ff9c663b-19ea-491d-a1af-d823a6845831': raiseСup, // trainer academy direction
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': raiseСup, // stage direction
  '01f94266-8371-42d6-bc52-961a53dc65e0': pilotRiseCup, // pilot academy direction
  'a7be9322-6431-4715-b088-d716ddc61bdf': pilotRiseCup, // pilot local host 3001
};

export const silverAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalThrowInBasket,
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalThrowInBasket,
  'ff9c663b-19ea-491d-a1af-d823a6845831': throwInBasket,
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': throwInBasket,
  '01f94266-8371-42d6-bc52-961a53dc65e0': pilotThrowInBasket,
  'a7be9322-6431-4715-b088-d716ddc61bdf': pilotThrowInBasket,
};

export const bronzeAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalDribbling,
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalDribbling,
  'ff9c663b-19ea-491d-a1af-d823a6845831': dribbling,
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': dribbling,
  '01f94266-8371-42d6-bc52-961a53dc65e0': pilotDribbling,
  'a7be9322-6431-4715-b088-d716ddc61bdf': pilotDribbling,
};

export const goldDarkAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalDarkRiseCup,
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalDarkRiseCup,
  'ff9c663b-19ea-491d-a1af-d823a6845831': raiseСupDark,
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': raiseСupDark,
  '01f94266-8371-42d6-bc52-961a53dc65e0': pilotDarkRiseCup,
  'a7be9322-6431-4715-b088-d716ddc61bdf': pilotDarkRiseCup,
};

export const silverDarkAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalDarkThrowInBasket,
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalDarkThrowInBasket,
  'ff9c663b-19ea-491d-a1af-d823a6845831': throwInBasketDark,
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': throwInBasketDark,
  '01f94266-8371-42d6-bc52-961a53dc65e0': pilotDarkThrowInBasket,
  'a7be9322-6431-4715-b088-d716ddc61bdf': pilotDarkThrowInBasket,
};

export const bronzeDarkAnimatedMapping = {
  '451553b1-e21e-4221-b248-e160177536e6': capitalDarkDribbling,
  '08ef0f0e-6223-4945-a3eb-f401986408f6': capitalDarkDribbling,
  'ff9c663b-19ea-491d-a1af-d823a6845831': dribblingDark,
  'da57d06c-d744-43ba-bb74-df4e4d02b8a9': dribblingDark,
  '01f94266-8371-42d6-bc52-961a53dc65e0': pilotDarkDribbling,
  'a7be9322-6431-4715-b088-d716ddc61bdf': pilotDarkDribbling,
};

import { Button, ScrollbarContainer, StyledText } from 'UI';
import { ManualTypeEnum } from 'api/generated';
import { UpgradeSubscriptionModal } from 'components';
import { accessLevels } from 'constant';
import { routes } from 'constant/routes';
import { useAsyncAction, useClientSize, useInfinityScroll, useNotifications, useToggle } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import React, { useCallback, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { PaginationType } from 'types';
import { trackAmplitudeEvent } from 'utils/trackAmplitudeEvent';

import { MnemonicCard } from './components';

function MnemonicCardList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { errorToast } = useNotifications();
  const { colors } = useTheme();
  const { getIsBreakpoint } = useClientSize();

  const {
    isOpen: isUpgradeSubscriptionModalOpen,
    close: closeUpgradeSubscriptionModal,
    open: openUpgradeSubscriptionModal,
  } = useToggle();

  const [getPackMnemonicCardsAction, isPacksLoading] = useAsyncAction(actions.mnemonicCardsPacks.getMnemonicCardsPacks);

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const isPause = useAppSelector(selectors.courses.selectIsPauseSubscription);
  const isPauseCertificate = useAppSelector(selectors.courses.selectIsPauseCertificate);
  const mnemonicCardsPacks = useAppSelector(selectors.mnemonicCardsPacks.selectMnemonicCardsPacks);
  const mnemonicCardsPacksMeta = useAppSelector(selectors.mnemonicCardsPacks.selectMnemonicCardsMeta);
  const clearFlashcardsSlice = () => dispatch(actions.mnemonicCardsPacks.clearSlice());

  const isWidthSm = getIsBreakpoint('sm');

  const { hasAllMnemonicDecks } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const nextPage = (mnemonicCardsPacksMeta?.currentPage || 0) + 1;

  const hasMnemonicCardsPackMore =
    (mnemonicCardsPacksMeta?.currentPage || 0) < (mnemonicCardsPacksMeta?.totalPages || 0);

  const isLoading = (!mnemonicCardsPacks && isPacksLoading) || !activeCourse;

  const openMnemonicCardsPack = useCallback((id: string) => {
    navigate(`${routes.mnemonicCards}/${id}`);
  }, []);

  const getPacksMnemonicCards = async (actionPayload?: PaginationType) => {
    try {
      await getPackMnemonicCardsAction(actionPayload);
    } catch (error) {
      errorToast('Something went wrong');
    }
  };

  const { handleScroll, listRef } = useInfinityScroll({
    getAfterData: () => getPacksMnemonicCards({ page: nextPage, limit: 20 }),
    hasAfterMore: hasMnemonicCardsPackMore,
    isLoading,
  });

  useEffect(() => {
    clearFlashcardsSlice();
    getPacksMnemonicCards({ limit: 20 });
  }, []);

  if (isLoading) {
    return (
      <MainLayout scrollContainerRef={listRef} onScroll={hasAllMnemonicDecks ? handleScroll : undefined}>
        <LoaderContainer>
          <ThreeDots color={colors.primary[3]} />
        </LoaderContainer>
      </MainLayout>
    );
  }

  return (
    <MainLayout onScroll={handleScroll} scrollContainerRef={listRef}>
      <ScrollbarContainer>
        <MainContainer>
          {!hasAllMnemonicDecks && (
            <Header>
              <Title font="title_4_bold_24">Demo mode</Title>
              <Button
                size="small"
                variant={isWidthSm ? 'secondary' : 'primary'}
                onClick={() => {
                  openUpgradeSubscriptionModal();
                  trackAmplitudeEvent('Upgrade for Full Access from Mnemonic Cards Pressed');
                }}>
                Upgrade
              </Button>
            </Header>
          )}
          {mnemonicCardsPacks?.map((pack, index) => {
            const disabled = hasAllMnemonicDecks && !isPause && !isPauseCertificate ? false : index > 0;

            return (
              <MnemonicCard
                key={pack.id}
                name={pack.name}
                total={pack.total}
                id={pack.id}
                onClick={(id) => {
                  if (disabled) {
                    openUpgradeSubscriptionModal();
                  } else {
                    openMnemonicCardsPack(id);
                    trackAmplitudeEvent(`${pack.name} Mnemonic Cards Pressed`);
                  }
                }}
                disabled={disabled}
              />
            );
          })}
          {isPacksLoading && (
            <LoadingWrapper>
              <ThreeDots color={colors.primary[3]} />
            </LoadingWrapper>
          )}
        </MainContainer>
      </ScrollbarContainer>
      <UpgradeSubscriptionModal
        // title="To get access to all decks of mnemonic cards, purchase  gold subscription" //todo
        isOpen={isUpgradeSubscriptionModalOpen}
        onClose={closeUpgradeSubscriptionModal}
        manualType={ManualTypeEnum.MnemonicsPageUpgrade}
      />
    </MainLayout>
  );
}

export default MnemonicCardList;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1224px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 28px 40px 40px 0;

  margin: 0 auto;

  ${respondToWidth.sm`
      gap: 16px;
      padding: 24px 12px 24px 16px ;
  `}
`;

const LoadingWrapper = styled.div`
  margin-inline: auto;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;

  button {
    width: fit-content;
    padding-inline: 32px;
  }
`;

const Title = styled(StyledText)``;

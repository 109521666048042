import { Stripe, StripeElements } from '@stripe/stripe-js';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { PaymentDtoLevelEnum, PaymentMethodDto, StripePaymentSource, SubscribeDtoPeriodEnum } from 'api/generated';
import { useAsyncAction, useStripeSetupForm } from 'hooks';
import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { actions } from 'store';
import { setErrorStripe } from 'utils';

interface CustomFormEvent<T = Element> extends FormEvent<T> {
  elementType?: string;
}

interface Props {
  elements: StripeElements | null;
  stripe: Stripe | null;
  clientSecret: string | undefined;
  courseId?: string;
  level?: PaymentDtoLevelEnum;
  source?: StripePaymentSource;
  period?: SubscribeDtoPeriodEnum;
  setTransactionId: Dispatch<SetStateAction<string | undefined>>;
}

const periodMapping = {
  [TermEnum.MONTHLY]: 'monthly',
  [TermEnum.YEARLY]: 'annual',
};

export const useStripeChangePlan = ({
  elements,
  stripe,
  clientSecret,
  courseId,
  level,
  source,
  period,
  setTransactionId,
}: Props) => {
  const [changeCourseSubscribeAction] = useAsyncAction(actions.courses.changeCourseSubscribe);

  const { handleSetupStripeForm } = useStripeSetupForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (e?: CustomFormEvent<HTMLFormElement>, paymentMethod?: PaymentMethodDto) => {
    if (e?.elementType !== 'expressCheckout') {
      e?.preventDefault();
    }

    setIsLoading(true);

    if (!stripe || !elements || !courseId || !level || !source || !period) return;

    try {
      if (paymentMethod && clientSecret && !paymentMethod.cashapp) {
        const res = await handleSetupStripeForm({ stripe, elements: undefined, clientSecret, paymentMethod });

        if (res?.error) {
          setErrorStripe({
            error: res?.error as any,
            otherErrorMessage: (res?.error as any).message || 'Something went wrong',
            setErrorMessage,
          });
          setIsLoading(false);
          return;
        }

        try {
          const setupIntent = (await stripe.retrieveSetupIntent(clientSecret)).setupIntent;
          setTransactionId(setupIntent?.id);
        } catch (error) {
          console.log(error);
        }
      }

      if (!paymentMethod && elements) {
        const res = await handleSetupStripeForm({ stripe, elements, clientSecret: '' });

        if (res?.error) {
          setErrorStripe({
            error: res?.error as any,
            otherErrorMessage: (res?.error as any).message || 'Something went wrong',
            setErrorMessage,
          });
          setIsLoading(false);
          return;
        }
      }

      const subscribeParams = {
        courseId,
        subscribeDto: {
          period: periodMapping[period] as SubscribeDtoPeriodEnum,
          type: level,
        },
      };

      await changeCourseSubscribeAction(subscribeParams);

      setIsLoading(false);

      setIsSuccess(true);
    } catch (error) {
      setErrorStripe({
        error: error as any,
        otherErrorMessage: (error as any).message,
        setErrorMessage,
      });
      setIsLoading(false);
    }
  };

  return {
    handleSubmit,
    isLoading,
    errorMessage,
    isSuccess,
  };
};

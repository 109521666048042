import StyledText from 'UI/StyledText';
import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { getReducedPrice } from 'utils';

export enum TermEnum {
  MONTHLY = 'monthly',
  YEARLY = 'annual',
  LIFE_TIME = 'lifeTime',
}

type PriceLabelProps = {
  oldPrice?: number | undefined;
  currentPrice: number;
  className?: string;
  isOnePriceLabelView?: boolean;
  goldCount?: number;
  countPlans?: number;
  termCount?: number;
  isMaxHeight?: boolean;
  term: TermEnum;
  isResponsive: boolean;
};

const PriceLabelLifetime: FC<PriceLabelProps> = ({
  oldPrice,
  currentPrice,
  term,
  isMaxHeight,
  goldCount,
  isResponsive,
  ...props
}) => {
  const getLeftIndent = (oldPrice: string, currentPrice?: string): string => {
    const length = oldPrice.length;

    if (currentPrice?.length === 8 && oldPrice.length === 4) {
      return '4px';
    }

    if (length === 2) {
      return '15px';
    } else if (length === 3) {
      return '8px';
    } else if (length === 4) {
      return '-2px';
    } else if (length === 5) {
      return '-5px';
    } else if (length === 6) {
      return '-12px';
    } else if (length === 7) {
      return '-14px';
    } else {
      return '0px';
    }
  };

  const getRightIndent = (oldPrice: number | undefined) => {
    if (!oldPrice) return;
    const stringOldPrice = oldPrice.toString();
    const length = stringOldPrice.length;
    console.log('🚀 ~ getRightIndent ~ length:', length);

    if (length === 4) {
      return '-4px';
    } else if (length === 5) {
      return '-4px';
    } else {
      return '2px';
    }
    // } else if (length === 3) {
    //   return '8px';
    // } else if (length === 4) {
    //   return '-2px';
    // } else if (length === 5) {
    //   return '-5px';
    // } else if (length === 6) {
    //   return '-12px';
    // } else if (length === 7) {
    //   return '-14px';
    // } else {
    //   return '0px';
    // }
  };

  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);
  const isPaymentPlan = paymentOption === 'paymentPlan';
  const oldPriceString = isPaymentPlan && oldPrice ? `$${getReducedPrice(oldPrice / 12)}` : `$${oldPrice}`;
  const currentPriceString = isPaymentPlan ? ` $${getReducedPrice(currentPrice / 12)}` : ` $${currentPrice}`;
  const period = isPaymentPlan ? 'monthly' : 'lifetime';
  const year = '12 month';

  const leftIndent = getLeftIndent(oldPriceString, currentPriceString);
  const rightIndent = getRightIndent(oldPrice);

  return (
    <Text {...props}>
      <Wrapper isResponsive={isResponsive}>
        {Boolean(oldPrice) && (
          <div style={{ position: 'absolute', bottom: '50px', left: isPaymentPlan ? leftIndent : '-6px' }}>
            <StrikethroughText as="span">{oldPriceString}</StrikethroughText>
          </div>
        )}

        <PriceContainer>
          <Price>{currentPriceString}</Price>
          <Period>{period}</Period>
        </PriceContainer>
        {isPaymentPlan && (
          <>
            {Boolean(oldPrice) && (
              <div style={{ position: 'absolute', bottom: '50px', right: rightIndent }}>
                <StrikethroughText as="span">${oldPrice}</StrikethroughText>
              </div>
            )}
            <PriceContainer>
              <Price>${currentPrice}</Price>
              <Period>{year}</Period>
            </PriceContainer>
          </>
        )}
      </Wrapper>
    </Text>
  );
};

export default PriceLabelLifetime;

const Wrapper = styled.div<{ isResponsive: boolean }>`
  display: flex;
  gap: ${({ isResponsive }) => (isResponsive ? '85px' : '28px')};
  position: relative;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.span`
  ${({ theme: { typography } }) => typography.title_5_bold_20};
`;

const Period = styled.span`
  ${({ theme: { typography } }) => typography.body_basic_semibold_14};
`;

const Text = styled(StyledText)`
  display: flex;
  gap: 4px;
  text-align: center;
  justify-content: center;

  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const StrikethroughText = styled(Text)`
  ${({ theme: { colors } }) => css`
    width: fit-content;
    position: relative;
    ${({ theme: { typography } }) => typography.title_4_bold_24};

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: line-through;
    color: ${colors.neutrals[5]};

    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      transform: translateY(1px);
      background-color: ${colors.neutrals[5]};
    }
  `}
`;

import React, { FC } from 'react';
import styled from 'styled-components';

interface CheckMarkProps {
  mainColor: any;
}

export const CheckmarkIcon: FC<CheckMarkProps> = ({ mainColor }) => {
  return (
    <Circle mainColor={mainColor}>
      <Checkmark />
    </Circle>
  );
};

const Circle = styled.div<{ mainColor: any }>`
  width: 20px; /* Диаметр круга */
  height: 20px; /* Диаметр круга */
  border-radius: 50%; /* Округление для создания круга */
  background-color: ${({ mainColor }) => mainColor}; /* Цвет фона круга */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkmark = styled.div`
  width: 11px; /* Ширина галочки */
  height: 7px; /* Толщина галочки */
  border: solid white; /* Цвет и стиль границы */
  border-width: 0px 0px 2.5px 2.5px; /* Формирование галочки */
  transform: rotate(-47deg); /* Поворот для создания галочки */
  margin-bottom: 2px;
`;
